import React from 'react'
import { useTranslation } from 'react-i18next'

import Grid from '@mui/material/Grid'
import Link from '@mui/material/Link'
import Typography from '@mui/material/Typography'

import { images } from '../assets/images'

export const Footer = () => {
  const currentYear = new Date().getFullYear()
  const { t } = useTranslation()

  return (
    <Grid container id="footer-container" flexDirection="column" my={2}>
      <Grid
        container
        item
        id="logo-container"
        justifyContent="center"
        alignItems="center"
      >
        <Typography variant="subtitle2" color="#000" sx={{ mr: 0.5 }}>
          {t('Powered by')}
        </Typography>
        <img src={images.logo} alt="Intelibly Trademarked Logo" width="100px" />
      </Grid>
      <Grid
        container
        item
        display="flex"
        id="copyright-container"
        justifyContent="center"
        alignItems="center"
      >
        <Typography variant="caption" sx={{ mr: 1 }}>
          © {currentYear} Intelibly
        </Typography>
        <Link variant="caption" href="https://app.intelibly.com/privacy-policy">
          {t('Privacy Policy')}
        </Link>
      </Grid>
    </Grid>
  )
}

// export default Footer
